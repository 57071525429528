import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Observable, map } from 'rxjs';
import { environment as env } from '@environments/environment';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { Campaign } from '@models/campaigns/campaign.model';
import { SaveCampaignRequest } from '@models/campaigns/dto/save-campaign.request';
import { CampaignStatus } from '@models/campaigns/campaign-status.enum';
import { GhostVotesRequest } from '@models/campaigns/dto/ghost-votes.request';

@Injectable()
export class CampaignService {
  constructor(private api: ApiService) {}

  getByUri(uri: string): Observable<Campaign> {
    return this.api
      .get(`${env.api}/campaigns/${uri}`)
      .pipe(map((it) => new Campaign(it)));
  }

  getPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<Campaign>> {
    return this.api
      .getPaged(`${env.api}/campaigns/paged`, pageNumber, pageSize, filters)
      .pipe(map((it) => this.mapPaginatedCampaigns(it)));
  }

  private mapPaginatedCampaigns(res: PageListingResponse<Campaign>) {
    return {
      total: res.total,
      records: res.records.map((it) => new Campaign(it)),
    };
  }

  getAll(filters: Map<string, string>): Observable<Campaign[]> {
    return this.api
      .get(`${env.api}/campaigns`, filters)
      .pipe(map((it) => this.mapCampaigns(it)));
  }

  private mapCampaigns(res: Campaign[]) {
    return res.map((it) => new Campaign(it));
  }

  saveCampaign(req: SaveCampaignRequest): Observable<Campaign> {
    return this.api
      .post(`${env.api}/campaigns`, req)
      .pipe(map((it) => new Campaign(it)));
  }

  setStatus(campaignId: number, status: CampaignStatus): Observable<Campaign> {
    return this.api
      .post(`${env.api}/campaigns/${campaignId}/set-status`, { status })
      .pipe(map((it) => new Campaign(it)));
  }

  delete(id: number): Observable<void> {
    return this.api.delete(`${env.api}/campaigns/${id}`);
  }

  sendGhostVotes(req: GhostVotesRequest): Observable<void> {
    return this.api.post(`${env.api}/events/ghost-votes`, req);
  }

  getActiveCampaign(req: Map<string, string>): Observable<Campaign> {
    return this.api.get(`${env.api}/campaigns/fetch-active`, req);
  }
}
